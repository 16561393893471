import Script from "next/script";

const OrgJSONData = {
	"@context"     : "https://schema.org",
	"@type"        : "Organization",
	"name"         : "Rooter",
	"legalName"    : "Rooter",
	"url"          : "https://www.rooter.gg/",
	"logo"         : "https://www.rooter.gg/images/rooter-logo-white.webp",
	"foundingDate" : "2016",
	"founders"     : [
		{
			"@type" : "Person",
			"name"  : "Piyush Kumar"
		},
		{
			"@type" : "Person",
			"name"  : "Dipesh Agarwal"
		} ],
	"address": {
		"@type"           : "PostalAddress",
		"streetAddress"   : "Community Centre, 2nd Floor, 55, East of Kailash",
		"addressLocality" : "New Delhi",
		"addressRegion"   : "Delhi",
		"postalCode"      : "110065",
		"addressCountry"  : "India"
	},
	"sameAs": [
		"https://www.instagram.com/rooter.gg/",
		"https://www.facebook.com/rootersports/",
		"https://www.linkedin.com/company/hello-rooter/?viewAsMember=true",
		"https://www.youtube.com/c/Rooter"
	]
};

const OrganizationSchema = () => (
	<Script
		dangerouslySetInnerHTML = { { __html: JSON.stringify ( OrgJSONData ) } }
		id = "organization-schema"
		type = "application/ld+json"
	/>
);

const SearchJSONData = {
	"@context"        : "https://schema.org/",
	"@type"           : "WebSite",
	"name"            : "Rooter",
	"url"             : "https://www.rooter.gg/",
	"potentialAction" : {
		"@type"       : "SearchAction",
		"target"      : "https://www.rooter.gg/{search_term_string}https://www.rooter.gg/search?q=",
		"query-input" : "required name=search_term_string"
	}
};

const SearchBoxSchema = () => (
	<Script
		dangerouslySetInnerHTML = { { __html: JSON.stringify ( SearchJSONData ) } }
		id = "search-schema"
		type = "application/ld+json"
	/>
);

const SiteNavigationJSONData = {
	"@context"        : "http://schema.org",
	"@type"           : "ItemList",
	"itemListElement" : [
		{
			"@type"       : "SiteNavigationElement",
			"position"    : 1,
			"name"        : "Rooter",
			"description" : "Join India’s biggest Free Fire Max, BGMI, Pubg, Minecraft, Valorant, GTA V gaming streamers like Jonathan Gaming, Lokesh Gamer, Ghatak, Team Godlike, Team Orangutan, Neyoo, Clutchgod, ZGod, Scout, Mavi, Team S8UL, Pahadi Gaming, Total Gaming.",
			"url"         : " https://www.rooter.gg/"
		},
		{
			"@type"       : "SiteNavigationElement",
			"position"    : 2,
			"name"        : "Live Now",
			"description" : "Watch live game streaming online with the best gaming streamers like Riyan Parag, Yuzvendra Chahal, Tanmay Bhatt, and Jonathan Gaming, and play top games like Free Fire, BGMI, and Valorant only on Rooter",
			"url"         : " https://www.rooter.gg/live"
		},
		{
			"@type"       : "SiteNavigationElement",
			"position"    : 3,
			"name"        : "About Us",
			"description" : "Know more about rooter: online gaming, esports tournaments and gaming platform in India",
			"url"         : " https://pages.rooter.gg/about-us "
		}

		// Uncomment once profile API for popular creators are ready

		//   {
		//   "@type"       : "SiteNavigationElement",
		//   "position"    : 4,
		//   "name"        : "Jonathan Gaming",
		//   "description" : "Watch Jonathan Gaming Live Stream BGMI on Rooter, Top Game Streaming Platform In India, Over 50 Million++ Gamers, Download The App Now!",
		//   "url"         : "'https://rooter.gg/profile/jonathan-gaming "
		//   },
		//   {
		//   "@type"       : "SiteNavigationElement",
		//   "position"    : 5,
		//   "name"        : "Lokesh Gamer07",
		//   "description" : " Watch LOKESH GAMER07 Live Stream Free Fire on Rooter, Top Game Streaming Platform In India, Over 50 Million+ Gamers, Download The App Now!",
		//   "url"         : " https://rooter.gg/profile/lokesh-gamer07/"
		// },
		//   {
		//   "@type"       : "SiteNavigationElement",
		//   "position"    : 6,
		//   "name"        : "Neyoo",
		//   "description" : " Watch Neyoo Live Stream BGMI on Rooter, Top Game Streaming Platform In India, Over 50 Million+ Gamers, Download The App Now!",
		//   "url"         : " https://rooter.gg/profile/neyoo/"
		//   },
		//   {
		//   "@type"       : "SiteNavigationElement",
		//   "position"    : 7,
		//   "name"        : "ZGOD GAMING",
		//   "description" : " Watch ZGOD GAMING Live Stream BGMI on Rooter, Top Game Streaming Platform In India, Over 50 Million+ Gamers, Download The App Now!",
		//   "url"         : "https://rooter.gg/profile/zgod-gaming"
		// },
		//   {
		//   "@type"       : "SiteNavigationElement",
		//   "position"    : 8,
		//   "name"        : "OG Pahadi Gaming",
		//   "description" : " Watch OG Pahadi Gaming Live Stream Free Fire on Rooter, Top Game Streaming Platform In India, Over 50 Million+ Gamers, Download The App Now!",
		//   "url"         : " https://rooter.gg/profile/og-pahadi-gaming/"
		// }
	]
};

const SiteNavigation = () => (
	<Script
		dangerouslySetInnerHTML = { { __html: JSON.stringify ( SiteNavigationJSONData ) } }
		id = "site-navigation-schema"
		type = "application/ld+json"
	/>
);

const LocalBusinessJSONData = {
	"@context"  : "https://schema.org",
	"@type"     : "LocalBusiness",
	"name"      : "Rooter",
	"image"     : "https://www.rooter.gg/images/rooter-logo-white.webp",
	"url"       : "https://www.rooter.gg/",
	"telephone" : "+91 9987079678",
	"address"   : {
		"@type"           : "PostalAddress",
		"streetAddress"   : "Community Centre, 2nd Floor, 55, East of Kailash",
		"addressLocality" : "New Delhi",
		"postalCode"      : "110065",
		"addressCountry"  : "Delhi, India"
	}
};

const LocalBusinessDataSchema = () => (
	<Script
		dangerouslySetInnerHTML = { { __html: JSON.stringify ( LocalBusinessJSONData ) } }
		id = "local-business-schema"
		type = "application/ld+json"
	/>
);

const Schema = () => {

	return (
		<>
			<OrganizationSchema />

			{/* <SearchBoxSchema /> */}

			<SiteNavigation />

			<LocalBusinessDataSchema />

		</>
	);
};

const ProfileJSONData = ( {
	name,
	id,
	image,
	followers
} ) => {
	return {
		"@context"             : "https://schema.org/",
		"@type"                : "Person",
		"name"                 : name,
		"url"                  : "https://www.rooter.gg/profile/" + id,
		"image"                : image,
		"interactionStatistic" : {
			"@type"           : "InteractionCounter",
			"interactionType" : {
				"@type": "FollowAction"
			},
			"userInteractionCount": followers
		}
	};
};

export const ProfileSchema = props => (
	<Script
		dangerouslySetInnerHTML = { { __html: JSON.stringify ( ProfileJSONData ( props ) ) } }
		id = "profile-schema"
		type = "application/ld+json"
	/>
);

export default Schema;
